/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.toast {
  --color: white;
  --background: #2d2d2d;
  --border-radius: 4px;
}
.alert-button.sc-ion-alert-ios:last-child {
  color: #333;
  border-right: 0;
  font-weight: bold;
}
.alert-message.sc-ion-alert-ios, .alert-input-group.sc-ion-alert-ios {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0;
  padding-bottom: 21px;
  color: var(--ion-text-color, #000);
  font-size: 14px;
  text-align: center;
}
.alert-button.sc-ion-alert-md:last-child {
  color: #333;
  border-right: 0;
  font-weight: bold;
}
.alert-message.sc-ion-alert-md, .alert-input-group.alert-message.sc-ion-alert-md {
padding-left: 16px;
padding-right: 16px;
padding-top: 0;
padding-bottom: 21px;
color: var(--ion-text-color, #000);
font-size: 14px;
text-align: center;
}
ion-button{
  text-transform: capitalize;
  --border-radius: 5px;

}
ion-toolbar{
  height: 100px;
  padding-right: 15px;
  padding-left: 15px;
}

ion-col{
  padding-left: 0;
  padding-right: 0;
}

a {
  color: #222428;
  border: none;
  font-size: 22px;
  font-weight: 700;
}